<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A reaction is performed in water inside of a calorimeter. The mass of solution inside the
        calorimeter was
        <number-value :value="sampleMass" unit="\text{g}" />
        and this resulted in a temperature change of
        <number-value :value="tempChange" unit="^\circ\text{C.}" />
      </p>

      <p class="mb-2">
        a) Determine the heat change of the solution assuming that the specific heat is
        <number-value :value="csoln" unit="\text{J g}^{-1}\text{ }^\circ\text{C}^{-1}" />
      </p>

      <calculation-input
        v-model="inputs.solutionHeat"
        prepend-text="$\text{Heat Change:}$"
        append-text="$\text{J}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Determine the heat change of the calorimeter itself if its heat capacity is
        <number-value :value="ccal" />
        <stemble-latex content="$\text{J/}^\circ\text{C}$" />
      </p>

      <calculation-input
        v-model="inputs.calorimeterHeat"
        prepend-text="$\text{Heat Change:}$"
        append-text="$\text{J}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">c) Determine the heat change of the reaction</p>

      <calculation-input
        v-model="inputs.reactionHeat"
        prepend-text="$\text{Heat Change:}$"
        append-text="$\text{J}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question77',
  components: {
    StembleLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        solutionHeat: null,
        calorimeterHeat: null,
        reactionHeat: null,
      },
    };
  },
  computed: {
    sampleMass() {
      return this.taskState.getValueBySymbol('sampleMass').content;
    },
    tempChange() {
      return this.taskState.getValueBySymbol('tempChange').content;
    },
    csoln() {
      return this.taskState.getValueBySymbol('csoln').content;
    },
    ccal() {
      return this.taskState.getValueBySymbol('ccal').content;
    },
  },
};
</script>
